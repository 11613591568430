import React, { useRef, useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { jwtDecode } from "jwt-decode";
import { Col, Row, Table, Button, Input, Space, Tag, Popconfirm } from 'antd';
import type { TableProps, InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import { NumericFormat } from 'react-number-format';
import usePutData from '../../../../hooks/usePutData';
import { useCustomer } from '../../../../hooks';
import Moment from 'react-moment';
import moment from 'moment';
import './styles.scss'

interface DataType {
  key: string;
  account_id: string;
  name: string;
  cust_start_date: Date;
  hurdle_rate: string;
  management_fee: string;
  performance_fee: string;
  benchmark: string;
  cust_end_date: Date;
  cur_date: Date;
  ref_code: string;
  risk_level: string;
  capital: string;
  care_by: string;
  updated_at: string;
  is_active: boolean;
}

type DataIndex = keyof DataType;

interface ComponentProps {
  data: any | null
}

const getLatestUpdated = (data: DataType[]): string => {
  const latestItem = data?.reduce((latest, item) => {
    const itemDate = new Date(item?.updated_at);
    return itemDate > new Date(latest?.updated_at) ? item : latest;
  }, data[0]);

  return latestItem?.updated_at;
};

const Customer: React.FC = () => {
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;
  const { mutate: putData, dataPut, errorPut } = usePutData();
  const { customer, mutate: mutateCustomer } = useCustomer();
  const uniqueCarebys: string[] = [];
  const uniqueRefs: string[] = [];

  const [customers, setCustomers] = useState<any[]>(customer || []);

  customers?.forEach((item: DataType) => {
    if (!uniqueCarebys.includes(item.care_by)) {
      uniqueCarebys.push(item.care_by);
    }
    if (!uniqueRefs.includes(item.ref_code) && item.ref_code !== '') {
      uniqueRefs.push(item.ref_code);
    }
  });

  const formattedCareBys = uniqueCarebys.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const formattedRefs = uniqueRefs.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: '#ffffff' }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const confirm = (record: DataType) => {
    return () => {
      putData('customer/status', {
        account_id: record.account_id,
        status: !record.is_active,
      });
    };
  };

  useEffect(() => {
    mutateCustomer();
    if (customer !== null) {
      setCustomers(customer);
    }
  }, [customer, dataPut]);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'account_id',
      key: 'account_id',
      align: 'center',
      width: 120,
      ...getColumnSearchProps('account_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Start',
      dataIndex: 'cust_start_date',
      key: 'cust_start_date',
      align: 'center',
      width: 100,
      sorter: (a, b) => moment(a.cust_start_date).unix() - moment(b.cust_start_date).unix(),
      render: (text) => <Moment format={'DD/MM/YYYY'}>{text}</Moment>,
    },
    {
      title: 'Capital',
      dataIndex: 'capital',
      key: 'capital',
      align: 'right',
      width: 120,
      sorter: (a, b) => parseFloat(a.capital) - parseFloat(b.capital),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Hurdle rate',
      dataIndex: 'hurdle_rate',
      key: 'hurdle_rate',
      align: 'right',
      width: 100,
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Mgmt. fee',
      dataIndex: 'management_fee',
      key: 'management_fee',
      align: 'right',
      width: 100,
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Perf. fee',
      dataIndex: 'performance_fee',
      key: 'performance_fee',
      align: 'right',
      width: 80,
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Benchmark',
      dataIndex: 'benchmark',
      key: 'benchmark',
      align: 'right',
      width: 100,
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Ref.',
      dataIndex: 'ref_code',
      key: 'ref_code',
      align: 'center',
      filters: formattedRefs,
      filterSearch: true,
      onFilter: (value, record) => record.ref_code.startsWith(value as string),
    },
    {
      title: 'Risk level',
      dataIndex: 'risk_level',
      key: 'risk_level',
      align: 'center',
      filters: [
        {
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Medium',
          value: 'medium',
        },
        {
          text: 'High',
          value: 'high',
        },
      ],
      onFilter: (value, record) => record.risk_level.startsWith(value as string),
    },
    {
      title: 'Care by',
      dataIndex: 'care_by',
      key: 'care_by',
      align: 'center',
      ellipsis: true,
      hidden: user?.role_name !== 'admin',
      filters: formattedCareBys,
      filterSearch: true,
      onFilter: (value, record) => record.care_by.startsWith(value as string),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Update customer status"
            description="Are you sure to change customer status"
            onConfirm={confirm(record)}
            okText="Yes"
            cancelText="No"
          >
            {record?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
          </Popconfirm>
        </>
      ),
    }
  ];

  return (
    <>
      <Table
        className={'c-ant-table'}
        columns={columns}
        dataSource={customers}
        size={'small'}
        bordered
        title={() => (
          <Row>
            <Col span={12} className={'left'}>
              CUSTOMER
            </Col>
            <Col span={12} className={'right'}>
              Last updated: <Moment format={'DD/MM/YYYY'}>{getLatestUpdated(customers)}</Moment>
            </Col>
          </Row>
        )} />
    </>
  );
};

export default Customer;
