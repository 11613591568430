import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Text } from '@chakra-ui/react';

interface PortfolioPerformanceEntry {
  date: string;
  ror: number;
  vnindex_ror: number;
}

interface ComponentProps {
  portfolioPerformance: PortfolioPerformanceEntry[] | null;
}

const PortfolioPerformance: React.FC<ComponentProps> = ({ portfolioPerformance }) => {

  // Kiểm tra nếu portfolioPerformance không null và có dữ liệu
  if (!portfolioPerformance || portfolioPerformance.length === 0) {
    return <Text align={'center'} fontSize={'xl'}>No data available</Text>;
  }

  // Chuyển đổi dữ liệu thành định dạng cho Highcharts
  const portfolioData = portfolioPerformance
    .map(entry => [new Date(entry.date).getTime(), Math.round(entry.ror * 100)]); // Làm tròn ror thành % và chuyển đổi date sang timestamp

  const vnindexData = portfolioPerformance
    .map(entry => [new Date(entry.date).getTime(), Math.round(entry.vnindex_ror * 100)]); // Làm tròn vnindex_ror thành % và chuyển date sang timestamp

  // Xác định ngày bắt đầu và kết thúc
  const startDate = new Date(portfolioPerformance[0].date).getTime(); // Ngày bắt đầu
  const endDate = new Date(portfolioPerformance[portfolioPerformance.length - 1].date).getTime(); // Ngày kết thúc

  const options = {
    chart: {
      type: 'area',
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime', // Thiết lập xAxis là datetime
      min: startDate, // Bắt đầu từ ngày đầu tiên
      max: endDate, // Kết thúc ở ngày cuối cùng
      allowDecimals: false
    },
    yAxis: {
      title: {
        text: 'Percentage (%)'
      },
      min: -100,
      max: 100, // Giới hạn phần trăm từ -100% đến 100%
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [{
      type: "area",
      name: 'Portfolio',
      data: portfolioData, // Dữ liệu ror
      color: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 1,
        },
        stops: [
          [0, 'rgba(31, 65, 114, 0.821)'],
          [1, 'rgba(31, 65, 114, 0.111)'],
        ],
      },
      lineWidth: 2,
      tooltip: {
        valueSuffix: '%' // Thêm hậu tố %
      }
    }, {
      type: "area",
      name: 'VNIndex',
      data: vnindexData, // Dữ liệu vnindex_ror
      color: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0,
        },
        stops: [
          [0, 'rgba(255, 122, 0, 0)'],
          [1, 'rgba(255, 122, 0, 0.71)'],
        ],
      },
      lineWidth: 2,
      tooltip: {
        valueSuffix: '%' // Thêm hậu tố %
      }
    }]
  };

  return (
    <div>
      <Text align={'center'} fontSize={'2xl'}>HIỆU QUẢ DANH MỤC ĐẦU TƯ</Text>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PortfolioPerformance;
