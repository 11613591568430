import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'antd';
import type { TableProps } from 'antd';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import './styles.scss';

interface DataType {
  key: string;
  symbol: string;
  sector: string;
  note: string;
  ref: string;
  last_updated: string;
}

interface ComponentProps {
  data: any | null
}

const getLatestUpdated = (data: DataType[]): string => {
  // Convert last_updated strings to Date objects and find the most recent one
  const latestItem = data?.reduce((latest, item) => {
    const itemDate = new Date(item?.last_updated);
    return itemDate > new Date(latest?.last_updated) ? item : latest;
  }, data[0]);

  return latestItem?.last_updated;
};

const WatchList: React.FC<ComponentProps> = ({ data }) => {

  const uniqueSymbols: string[] = [];
  const uniqueSectors: string[] = [];

  data?.forEach((item: DataType) => {
    if (!uniqueSymbols.includes(item.symbol)) {
      uniqueSymbols.push(item.symbol);
    }

    if (!uniqueSectors.includes(item.sector)) {
      uniqueSectors.push(item.sector);
    }
  });

  const formattedSymbols = uniqueSymbols.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const formattedSectors = uniqueSectors.map(sector => ({
    text: sector,
    value: sector
  }));

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Ticker',
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'center',
      filters: formattedSymbols,
      filterSearch: true,
      onFilter: (value, record) => record.symbol.startsWith(value as string),
    },
    {
      title: 'Targe Price',
      dataIndex: 'target_price',
      key: 'target_price',
      align: 'right',
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      align: 'center',
      filters: formattedSectors,
      filterSearch: true,
      onFilter: (value, record) => record.sector.startsWith(value as string),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
    },
    {
      title: 'Ref',
      dataIndex: 'ref',
      key: 'ref',
      align: 'center',
      render: (text) => <Link to={text}>{text}</Link>,
    }
  ];

  return (
    <>
      <Table
        className='c-ant-table'
        columns={columns}
        dataSource={data}
        size={'small'}
        bordered
        title={() => (
          <Row>
            <Col span={12} className='left'>
              WATCHLIST
            </Col>
            <Col span={12} className='right'>
              Last updated: <Moment format={'DD/MM/YYYY'}>{getLatestUpdated(data)}</Moment>
            </Col>
          </Row>
        )} />
    </>
  );
};

export default WatchList;
